<template>
  <vx-card :title="title">
    <vs-tabs :value="activeTab">
      <vs-tab label="History">
        <History></History>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import History from "./_tab/History.vue"

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    History,
  },
  data() {
    return {
      title: "Approval History",
      activeTab : this.$route.query.tab ? this.$route.query.tab : 0
    };
  },
};
</script>
