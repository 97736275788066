<template>
  <multiselect
    :filterable="true"
    :options="options"
    v-model="selected"
    :custom-label="label"
    :placeholder="placeholder"
    :track-by="'id'"
  />
</template>

<script>
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    draw: {
      type: Number,
    },
    placeholder: {
      type: String
    },
    value: {
      type: Object
    },
    approvalTypeID: {
      type: Number,
    }
  },
  data() {
    return {
      baseUrl: "/api/wms/v1/approval-history/document-number",
      options: [],
      selected: (this.multiple) ? [] : null
    };
  },
  methods: {
    get() {
      console.log("Fetching document numbers with approvalTypeID:", this.approvalTypeID);
      if (this.approvalTypeID) {
        this.$http
          .get(this.baseUrl, {
            params: {
              order: "reference_code",
              sort: "asc",
              approval_type_id: this.approvalTypeID,
            }
          })
          .then(resp => {
            console.log("API response:", resp);
            if (resp.code === 200) {
              resp.data.records.map(function (x) {
                return (x.label = x.reference_code);
              });

              this.options = resp.data.records;
            } else {
              this.options = []
              console.log("Error fetching data:", resp.data);
            }
          })
          .catch(error => {
            console.log("API call error:", error);
          });
      }
    },
    label(option) {
      return option.label;
    },
  },
  watch: {
    selected(newVal) {
      this.$emit('selected', newVal);
    },
    approvalTypeID(newVal) {
      console.log("approvalTypeID changed:", newVal);
      this.get();
    },
    value(newVal) {
      this.selected = newVal;
    }
  },
  mounted() {
    this.selected = this.value;
  }
};
</script>
